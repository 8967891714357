import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Container, Box, Button, CircularProgress, Typography, IconButton, Tooltip, Dialog, DialogTitle, DialogContent, TextField, DialogActions } from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';
import AnimatedTable from './AnimatedTable';
import { format } from 'date-fns';

const MyMachines = () => {
  const [machines, setMachines] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [firstLoad, setFirstLoad] = useState(true);
  const [open, setOpen] = useState(false);
  const [currentMachine, setCurrentMachine] = useState(null);
  const [issueDescription, setIssueDescription] = useState('');

  useEffect(() => {
    const storedMachines = sessionStorage.getItem('machines');
    if (storedMachines) {
      setMachines(JSON.parse(storedMachines));
      setFirstLoad(false);
    }
  }, []);

  const fetchMachines = async () => {
    setLoading(true);
    setError(null);
    try {
      const token = sessionStorage.getItem('token');
      if (!token) {
        setError('No authentication token found. Please log in again.');
        setLoading(false);
        return;
      }

      const triggerResponse = await axios.post('/api/zapier-machines', null, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (triggerResponse.data.message === 'Machine data is ready.') {
        const machinesResponse = await axios.get('/api/machines', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setMachines(machinesResponse.data.machines);
        sessionStorage.setItem('machines', JSON.stringify(machinesResponse.data.machines));
      } else {
        await new Promise(resolve => setTimeout(resolve, 60000));
        const machinesResponse = await axios.get('/api/machines', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setMachines(machinesResponse.data.machines);
        sessionStorage.setItem('machines', JSON.stringify(machinesResponse.data.machines));
      }
    } catch (error) {
      if (error.response) {
        switch (error.response.status) {
          case 401:
            setError('Authentication failed. Please log in again.');
            break;
          case 403:
            setError('Session expired. Please log in again.');
            break;
          case 404:
            setError('No machine data available. Please try again later.');
            break;
          default:
            setError(`Error fetching machine data: ${error.response.data.message || 'Unknown error'}`);
        }
      } else {
        setError('Network error. Please check your connection and try again.');
      }
    }
    setLoading(false);
    setFirstLoad(false);
  };

  const handleOpen = (machine) => {
    setCurrentMachine(machine);
    setIssueDescription('');
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setCurrentMachine(null);
  };

  const handleSubmit = async () => {
    if (!currentMachine) return;

    const currentDate = format(new Date(), 'yyyy-MM-dd');
    const currentTime = format(new Date(), 'HH:mm');

    const payload = {
      serial: currentMachine.serialNumber,
      date: currentDate,
      time: currentTime,
      issue_description: issueDescription,
    };

    try {
      await axios.post('/api/support-case', payload, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
          'Content-Type': 'application/json',
        },
      });
      console.log('Support case submitted successfully');
    } catch (error) {
      console.error('Error submitting support case:', error);
    }

    handleClose();
  };

  const headers = ["Serial Number", "Model", "Status", "Install Date", "Purchase Date", "Trocare Start", "Trocare End", "Actions", "Support"];

const machineDataWithButtons = machines.map(machine => ({
  ...machine,
  Actions: (
<Button 
  variant="contained" 
  href="https://troteclaser.jotform.com/form/232044384806253" 
  target="_blank"
  sx={{ width: '100px' }} // Adjust the width value as per your requirement
>
  Book PM
</Button>
  ),
  Support: (
    <Button variant="contained" onClick={() => handleOpen(machine)}>
      Support
    </Button>
  )
}));
  return (
    <Container maxWidth="lg" sx={{ mt: 4 }}>
      {loading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
          <CircularProgress />
          <Typography sx={{ ml: 2 }}>Loading, please wait...</Typography>
        </Box>
      ) : (
        <>
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', mb: 2 }}>
            {firstLoad ? (
              <Button variant="contained" onClick={fetchMachines}>
                Get Machine Data
              </Button>
            ) : (
              <Tooltip title="Refresh">
                <IconButton onClick={fetchMachines}>
                  <RefreshIcon />
                </IconButton>
              </Tooltip>
            )}
          </Box>
          {error && <Typography color="error" gutterBottom>{error}</Typography>}
          {machineDataWithButtons.length > 0 && <AnimatedTable headers={headers} data={machineDataWithButtons} />}
          {!loading && machineDataWithButtons.length === 0 && (
            <Typography>No machine data available. Click "Get Machine Data" to fetch your machines.</Typography>
          )}
        </>
      )}

      {/* Modal for Support Case */}
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Create Support Case</DialogTitle>
        <DialogContent>
          <TextField
            margin="dense"
            label="Serial"
            type="text"
            fullWidth
            variant="outlined"
            value={currentMachine ? currentMachine.serialNumber : ''}
            InputProps={{
              readOnly: true,
            }}
          />
          <TextField
            margin="dense"
            label="Date"
            type="date"
            fullWidth
            variant="outlined"
            value={format(new Date(), 'yyyy-MM-dd')}
            InputProps={{
              readOnly: true,
            }}
          />
          <TextField
            margin="dense"
            label="Time"
            type="time"
            fullWidth
            variant="outlined"
            value={format(new Date(), 'HH:mm')}
            InputProps={{
              readOnly: true,
            }}
          />
          <TextField
            margin="dense"
            label="Issue Description"
            type="text"
            fullWidth
            variant="outlined"
            multiline
            rows={4}
            value={issueDescription}
            onChange={(e) => setIssueDescription(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleSubmit} variant="contained" color="primary">
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default MyMachines;
