import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import { ThemeProvider } from '@mui/material/styles';
import { CircularProgress, Box } from '@mui/material';
import { motion, AnimatePresence } from 'framer-motion';
import theme from './theme';
import Login from './Login';
import MyMachines from './MyMachines';
import MyCases from './MyCases';
import MyOrders from './MyOrders';
import Portal from './Portal';

const AppContent = ({ isAuthenticated, setIsAuthenticated, handleLogout }) => {
  const location = useLocation();

  return (
    <AnimatePresence mode="wait">
      <Routes location={location} key={location.pathname}>
        <Route
          path="/"
          element={
            isAuthenticated ? (
              <Navigate to="/portal" replace />
            ) : (
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.5 }}
              >
                <Login setIsAuthenticated={setIsAuthenticated} />
              </motion.div>
            )
          }
        />
        <Route
          path="/portal/*"
          element={
            isAuthenticated ? (
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.5 }}
              >
                <Portal isAuthenticated={isAuthenticated} onLogout={handleLogout} />
              </motion.div>
            ) : (
              <Navigate to="/" replace />
            )
          }
        >
          <Route path="machines" element={<MyMachines />} />
          <Route path="cases" element={<MyCases />} />
          <Route path="orders" element={<MyOrders />} />
          <Route index element={<Navigate to="machines" replace />} />
        </Route>
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
    </AnimatePresence>
  );
};

const App = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const checkAuthStatus = async () => {
      const token = sessionStorage.getItem('token');
      if (token) {
        try {
          await axios.get('https://portal.mytrotec.com/api/auth-status', {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          setIsAuthenticated(true);
        } catch (error) {
          console.error('Token validation failed:', error);
          sessionStorage.removeItem('token');
        }
      }
      setLoading(false);
    };

    checkAuthStatus();
  }, []);

  const handleLogout = () => {
    sessionStorage.removeItem('token');
    setIsAuthenticated(false);
  };

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <ThemeProvider theme={theme}>
      <Router>
        <AppContent
          isAuthenticated={isAuthenticated}
          setIsAuthenticated={setIsAuthenticated}
          handleLogout={handleLogout}
        />
      </Router>
    </ThemeProvider>
  );
};

export default App;
