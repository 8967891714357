import React, { useState } from 'react';
import axios from 'axios';
import { Container, Box, Button, CircularProgress, Typography, IconButton, Tooltip } from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';
import AnimatedTable from './AnimatedTable';

const MyOrders = () => {
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [firstLoad, setFirstLoad] = useState(true);

  const fetchOrders = async () => {
    setLoading(true);
    setError(null);
    try {
      const token = sessionStorage.getItem('token');
      if (!token) {
        setError('No authentication token found. Please log in again.');
        setLoading(false);
        return;
      }

      console.log('Fetching order data...');
      const ordersResponse = await axios.post('/api/orders', null, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      console.log('Order data response:', ordersResponse.data);
      setOrders(ordersResponse.data.orders || []);
    } catch (error) {
      console.error('Error in fetchOrders:', error);
      if (error.response) {
        console.error('Error response:', error.response.data);
        switch (error.response.status) {
          case 401:
            setError('Authentication failed. Please log in again.');
            break;
          case 403:
            setError('Session expired. Please log in again.');
            break;
          case 404:
            setError('No order data available. Please try again later.');
            break;
          default:
            setError(`Error fetching order data: ${error.response.data.message || 'Unknown error'}`);
        }
      } else {
        setError('Network error. Please check your connection and try again.');
      }
    }
    setLoading(false);
    setFirstLoad(false);
  };

  const headers = ["Order Number", "Order Date", "Order Status", "Order Total"];

  return (
    <Container maxWidth="lg" sx={{ mt: 4 }}>
      {loading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
          <CircularProgress />
          <Typography sx={{ ml: 2 }}>Loading, please wait...</Typography>
        </Box>
      ) : (
        <>
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', mb: 2 }}>
            {firstLoad ? (
              <Button variant="contained" color="primary" onClick={fetchOrders}>
                Get Order Data
              </Button>
            ) : (
              <Tooltip title="Refresh">
                <IconButton color="primary" onClick={fetchOrders}>
                  <RefreshIcon />
                </IconButton>
              </Tooltip>
            )}
          </Box>
          {error && <Typography color="error" gutterBottom>{error}</Typography>}
          {orders.length > 0 && <AnimatedTable headers={headers} data={orders} />}
          {!loading && orders.length === 0 && (
            <Typography>No order data available. Click "Get Order Data" to fetch your orders.</Typography>
          )}
        </>
      )}
    </Container>
  );
};

export default MyOrders;
