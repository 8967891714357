import React, { useEffect, useState } from 'react';
import { Link, NavLink, Outlet, useLocation } from 'react-router-dom';
import { AppBar, Toolbar, Button, Box } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  appBar: {
    backgroundColor: '#fff',
  },
  logo: {
    height: '90px',
  },
  navLink: {
    color: theme.palette.text.primary,
    textDecoration: 'none',
  },
  activeNavLink: {
    color: theme.palette.primary.main,
    fontWeight: 'bold',
  },
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  navButtons: {
    display: 'flex',
    gap: theme.spacing(2),
  },
  logoutButton: {
    color: theme.palette.text.primary,
  },
}));

const Portal = ({ onLogout }) => {
  const classes = useStyles();
  const location = useLocation();
  const [ws, setWs] = useState(null);

  useEffect(() => {
    const webSocket = new WebSocket('wss://portal.mytrotec.com/ws');

    webSocket.onopen = () => {
      console.log('WebSocket connection opened');
    };

    webSocket.onmessage = (message) => {
      console.log('WebSocket message received:', message);
    };

    webSocket.onerror = (error) => {
      console.error('WebSocket error:', error);
    };

    webSocket.onclose = () => {
      console.log('WebSocket connection closed');
    };

    setWs(webSocket);

    return () => {
      webSocket.close();
    };
  }, []);

  return (
    <Box>
      <AppBar position="static" className={classes.appBar}>
        <Toolbar className={classes.toolbar}>
          <Box>
            <Link to="/portal/machines">
              <img
                src="https://upload.wikimedia.org/wikipedia/commons/1/13/Trotec_Logo_2020_rgb_highres.jpg"
                alt="Trotec Logo"
                className={classes.logo}
              />
            </Link>
          </Box>
          <Box className={classes.navButtons}>
            <Button
              component={NavLink}
              to="/portal/machines"
              className={`${classes.navLink} ${location.pathname === '/portal/machines' ? classes.activeNavLink : ''}`}
            >
              My Machines
            </Button>
            <Button
              component={NavLink}
              to="/portal/cases"
              className={`${classes.navLink} ${location.pathname === '/portal/cases' ? classes.activeNavLink : ''}`}
            >
              My Cases
            </Button>
            {/* <Button
              component={NavLink}
              to="/portal/orders"
              className={`${classes.navLink} ${location.pathname === '/portal/orders' ? classes.activeNavLink : ''}`}
            >
              My Orders
            </Button> */}
            <Button
              className={classes.logoutButton}
              onClick={onLogout}
            >
              Logout
            </Button>
          </Box>
        </Toolbar>
      </AppBar>
      <main>
        <Outlet />
      </main>
    </Box>
  );
};

export default Portal;
