import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Container, Box, Button, CircularProgress, Typography, IconButton, Tooltip } from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';
import AnimatedTable from './AnimatedTable';

const MyCases = () => {
  const [cases, setCases] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [firstLoad, setFirstLoad] = useState(true);

  useEffect(() => {
    const storedCases = sessionStorage.getItem('cases');
    if (storedCases) {
      setCases(JSON.parse(storedCases));
      setFirstLoad(false);
    }
  }, []);

  const fetchCases = async () => {
    setLoading(true);
    setError(null);
    try {
      const token = sessionStorage.getItem('token');
      if (!token) {
        setError('No authentication token found. Please log in again.');
        setLoading(false);
        return;
      }

      const triggerResponse = await axios.post('/api/zapier-cases', null, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (triggerResponse.data.message === 'Case data is ready.') {
        const casesResponse = await axios.get('/api/cases', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setCases(casesResponse.data.cases);
        sessionStorage.setItem('cases', JSON.stringify(casesResponse.data.cases));
      } else {
        await new Promise(resolve => setTimeout(resolve, 60000));
        const casesResponse = await axios.get('/api/cases', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setCases(casesResponse.data.cases);
        sessionStorage.setItem('cases', JSON.stringify(casesResponse.data.cases));
      }
    } catch (error) {
      if (error.response) {
        switch (error.response.status) {
          case 401:
            setError('Authentication failed. Please log in again.');
            break;
          case 403:
            setError('Session expired. Please log in again.');
            break;
          case 404:
            setError('No case data available. Please try again later.');
            break;
          default:
            setError(`Error fetching case data: ${error.response.data.message || 'Unknown error'}`);
        }
      } else {
        setError('Network error. Please check your connection and try again.');
      }
    }
    setLoading(false);
    setFirstLoad(false);
  };

  const headers = ["Case Number", "Machine", "Subject", "Opened Date", "Status"];
  
  return (
    <Container maxWidth="lg" sx={{ mt: 4 }}>
      {loading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
          <CircularProgress />
          <Typography sx={{ ml: 2 }}>Loading, please wait...</Typography>
        </Box>
      ) : (
        <>
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', mb: 2 }}>
            {firstLoad ? (
              <Button variant="contained" color="primary" onClick={fetchCases}>
                Get Case Data
              </Button>
            ) : (
              <Tooltip title="Refresh">
                <IconButton color="primary" onClick={fetchCases}>
                  <RefreshIcon />
                </IconButton>
              </Tooltip>
            )}
          </Box>
          {error && <Typography color="error" gutterBottom>{error}</Typography>}
          {cases.length > 0 && <AnimatedTable headers={headers} data={cases} />}
          {!loading && cases.length === 0 && (
            <Typography>No case data available. Click "Get Case Data" to fetch your cases.</Typography>
          )}
        </>
      )}
    </Container>
  );
};

export default MyCases;
