import React, { useState } from 'react';
import axios from 'axios';
import { Container, TextField, Button, Typography, CircularProgress } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
  },
  logo: {
    marginBottom: theme.spacing(4),
    height: '90px', // Adjusted size for a 50% increase
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

const Login = ({ setIsAuthenticated }) => {
  const classes = useStyles();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');

  console.log("Login component rendered");

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setMessage('');

    try {
      const response = await axios.post('https://portal.mytrotec.com/api/login', {
        email,
        password
      });

      if (response.data.message === 'Please wait...') {
        setMessage('Verifying your information...');
        pollAuthStatus();
      } else {
        setMessage('Unexpected response. Please try again.');
        setLoading(false);
      }
    } catch (error) {
      console.error('Error during login:', error);
      setMessage('Error submitting login. Please try again.');
      setLoading(false);
    }
  };

  const pollAuthStatus = async () => {
    const pollInterval = setInterval(async () => {
      try {
        const response = await axios.get('https://portal.mytrotec.com/api/auth-status');
        if (response.data.authenticated === true) {
          clearInterval(pollInterval);
          setIsAuthenticated(true);
          sessionStorage.setItem('token', response.data.token);
          setMessage('Login Successful');
          setLoading(false);
        } else if (response.data.authenticated === false) {
          clearInterval(pollInterval);
          setMessage('Login Failed. Please try again.');
          setLoading(false);
        }
      } catch (error) {
        console.error('Error checking auth status:', error);
        clearInterval(pollInterval);
        setMessage('Error checking login status. Please try again.');
        setLoading(false);
      }
    }, 3000);
  };

  return (
    <Container component="main" maxWidth="xs" className={classes.container}>
      <img 
        src="https://upload.wikimedia.org/wikipedia/commons/1/13/Trotec_Logo_2020_rgb_highres.jpg" 
        alt="Trotec Logo" 
        className={classes.logo} 
      />
      <form className={classes.form} onSubmit={handleSubmit}>
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="email"
          label="Ruby Super User Email Address"
          name="email"
          autoComplete="email"
          autoFocus
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          name="password"
          label="Ruby Super User Password"
          type="password"
          id="password"
          autoComplete="current-password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          className={classes.submit}
          disabled={loading}
        >
          {loading ? <CircularProgress size={24} /> : 'Login'}
        </Button>
      </form>
      {message && (
        <Typography variant="body2" color="error">
          {message}
        </Typography>
      )}
    </Container>
  );
};

export default Login;
